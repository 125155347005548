<template>
  <v-dialog v-model="showDialog" max-width="800px" :fullscreen="$vuetify.breakpoint.mobile">
    <v-card>
      <v-card-title class="headline">
        Terms and Conditions
        <v-btn icon @click="closeDialog" class="close-icon">
          <v-icon color="red">close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text >
        <div class="terms-content" :style="{color: 'var(--v-primaryText-base)'}" v-html="content"></div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: { showDialog: Boolean, version: String },
  data() {
    return {
      content: '',
      loading: true,
      error: null,
    };
  },
  async mounted() {
    await this.fetchTermsAndConditions();
  },
  methods: {
    async fetchTermsAndConditions() {
      try {
        const response = await this.$API.getTerms();
        if (response && response.content) {
          this.content = response.content;
          this.loading = false;
        } else {
          throw new Error('Unexpected response format');
        }
      } catch (error) {
        this.error = 'Failed to load terms and conditions.';
        this.loading = false;
      }
    },
    closeDialog() {
      this.$emit('close', this.version);
    }
  }
};
</script>

<style scoped>
.scrollable-content {
  max-height: 500px;
  overflow-y: auto;
}
.terms-content {
  padding: 16px;
  font-size: 14px;
  line-height: 1.6;
}

.v-card-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  background-color: #f5f5f5;
  border-bottom: 1px solid #ddd;
}

.close-icon {
  position: absolute;
  top: 16px;
  right: 16px;
}

.v-btn {
  margin: 0;
  padding: 0;
}

.v-btn .v-icon {
  font-size: 24px;
}
</style>
